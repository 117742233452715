import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// Compoenents
import JaLayout from '../../../components/JaLayout';
import JaAboutBanner from '../../../components/JaAboutBanner';
import UnderConstruction from '../../../components/UnderConstruction';

// Images
import Hero from '../../../img/about_06_m_w.jpg';
import MobHEro from '../../../img/about_06_m_h.jpg';
import CustomOne from '../../../img/about_06_i_01.svg';
import CustomTwo from '../../../img/about_06_i_02.svg';
import CustomThree from '../../../img/about_06_i_03.svg';
import CustomFour from '../../../img/about_06_i_04.svg';
import CustomFive from '../../../img/about_06_i_05.svg';
import LeftArr from '../../../img/arrow-left.svg';
import DownArr from '../../../img/arrow-down.svg';

// Language
import { getPackOf } from '../../../lang';
const LANG = getPackOf('ja');

const AboutCert = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/about-ja/cert.md" }) {
        frontmatter {
          disable
          certificateFile {
            publicURL
          }
          twPatent {
            fileSource {
              publicURL
            }
            title
            disable
          }
          jpPatent {
            title
            fileSource {
              publicURL
            }
            disable
          }
          dePatent {
            title
            fileSource {
              publicURL
            }
            disable
          }
          usPatent {
            title
            fileSource {
              publicURL
            }
            disable
          }
        }
      }
    }
  `);

  const {
    markdownRemark: {
      frontmatter: { disable, certificateFile, twPatent, jpPatent, dePatent, usPatent },
    },
  } = data;

  return (
    <JaLayout>
      <JaAboutBanner pageTitle={LANG.品質與認證} subTitle={LANG.品質與認證副標題} bgImg={Hero} mobileBgImg={MobHEro} />
      {disable ? (
        <UnderConstruction />
      ) : (
        <>
          <section id="about_06_01" className="py-5">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12">
                  <p className="h3">{LANG.品質保證}</p>
                  <h5 style={{ color: 'rgba(74,74,74)' }}>
                    <strong>{LANG.客製化接案流程}</strong>
                  </h5>
                </div>
                {[
                  { title: LANG.場勘評估, content: LANG.場勘評估內文, img: CustomOne },
                  { title: LANG.執行建議, content: LANG.執行建議內文, img: CustomTwo },
                  { title: LANG.圖稿設計, content: LANG.圖稿設計內文, img: CustomThree },
                  { title: LANG.產品製造, content: LANG.產品製造內文, img: CustomFour },
                  { title: LANG.出貨, content: LANG.出貨內文, img: CustomFive },
                ].map((e, i) => {
                  return (
                    <div className="col-12 col-md-4 my-1" key={e.title}>
                      <div className="row mx-0 bg-warning rounded">
                        <div className="w-25 text-center">
                          <span className="display-4">
                            <strong>{i + 1}</strong>
                          </span>
                        </div>
                        <div className="w-50 p-2 border-left border-white">
                          <span>
                            <strong>{e.title}</strong>
                            <br />
                            {e.content}
                          </span>
                        </div>
                        <div className="w-25 pr-2 text-center">
                          <img src={e.img} alt="custom" />{' '}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div id="" className="col-12 pb-3">
                  <hr className="my-4" />
                </div>
                <div id="" className="col-12 pb-3">
                  <h5 style={{ color: 'rgba(74,74,74)' }}>
                    <strong>{LANG.品管流程}</strong>
                  </h5>
                </div>
                <div id="about_06_01_qa" className="w-100">
                  <div className="col-12 my-1 align-items-center">
                    <div className="row mx-0 bg-light rounded">
                      <div className="col-2 col-md-3 p-1 text-center">
                        <span>
                          <strong>{LANG.品管}</strong>
                        </span>
                      </div>
                      <div className="col-1 p-1 text-center">&nbsp;</div>
                      <div className="col-2 col-md-3 p-1 text-center">
                        <strong>{LANG.製程}</strong>
                      </div>
                      <div className="col p-0 border-left border-white">
                        <ul className="mb-0">
                          <li>
                            <span>{LANG.檢驗項目}</span>{' '}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {[
                    { qc: LANG.品管流程內容aa, mfg: LANG.品管流程內容ab, testItems: LANG.品管流程內容ac },
                    { qc: LANG.品管流程內容ba, mfg: LANG.品管流程內容bb, testItems: LANG.品管流程內容bc },
                    { qc: LANG.品管流程內容ca, mfg: LANG.品管流程內容cb, testItems: LANG.品管流程內容cc },
                    { qc: LANG.品管流程內容da, mfg: LANG.品管流程內容db, testItems: LANG.品管流程內容dc },
                    { qc: LANG.品管流程內容ea, mfg: LANG.品管流程內容eb, testItems: LANG.品管流程內容ec },
                    { qc: LANG.品管流程內容fa, mfg: LANG.品管流程內容fb, testItems: LANG.品管流程內容fc },
                  ].map((e, i) => {
                    return (
                      <>
                        <div className="col-12 my-1" key={e.qc}>
                          <div className="row mx-0 bg-info rounded text-white align-items-center mh-100">
                            <div className="col-2 col-md-3 p-1 text-center">
                              <span className="h5">
                                <strong>{e.qc}</strong>
                              </span>
                            </div>
                            <div className="col-1 p-1 text-center">
                              {i !== 5 && <img src={LeftArr} alt="fa-long-arrow-alt-left" />}
                            </div>
                            <div className="col-2 col-md-3 p-1 text-center">
                              <span className="h5">
                                <strong>{e.mfg}</strong>
                              </span>
                            </div>
                            <div className="col p-0 border-left border-white">
                              <ul className="mb-0">
                                <li>
                                  <span>{e.testItems[0]}</span>
                                </li>
                                <li>
                                  <span>{e.testItems[1]}</span>{' '}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {i !== 5 && (
                          <div className="col-12 my-1 text-center">
                            <img src={DownArr} alt="fa-arrow-down" width="50" />
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
          <section id="about_06_02" className="py-5 bg-img bg-light">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12">
                  <p className="h3">{LANG.專利認證}</p>
                </div>
                <div className="col-12 col-md-6">
                  <h5 style={{ color: 'rgba(74,74,74)' }}>
                    <strong>{LANG.台灣專利}</strong>
                  </h5>
                  <ul>
                    {twPatent &&
                      twPatent.map((p) => {
                        return (
                          <li key={p.title}>
                            {p.fileSource && !p.disable ? (
                              <a href={p.fileSource.publicURL} target="_blank" rel="noreferrer">
                                {p.title}
                              </a>
                            ) : (
                              `${p.title}`
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <h5 style={{ color: 'rgba(74,74,74)' }}>
                    <strong>{LANG.日本專利}</strong>
                  </h5>
                  <ul>
                    {jpPatent &&
                      jpPatent.map((j) => {
                        return (
                          <li key={j.title}>
                            {j.fileSource && !j.disable ? (
                              <a href={j.fileSource.publicURL} target="_blank" rel="noreferrer">
                                {j.title}
                              </a>
                            ) : (
                              `${j.title}`
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <h5 style={{ color: 'rgba(74,74,74)' }}>
                    <strong>{LANG.德國專利}</strong>
                  </h5>
                  <ul>
                    {dePatent &&
                      dePatent.map((d) => {
                        return (
                          <li key={d.title}>
                            {d.fileSource && !d.disable ? (
                              <a href={d.fileSource.publicURL} target="_blank" rel="noreferrer">
                                {d.title}
                              </a>
                            ) : (
                              `${d.title}`
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <h5 style={{ color: 'rgba(74,74,74)' }}>
                    <strong>{LANG.美國專利}</strong>
                  </h5>
                  <ul>
                    {usPatent &&
                      usPatent.map((u) => {
                        return (
                          <li key={u.title}>
                            {u.fileSource && !u.disable ? (
                              <a href={u.fileSource.publicURL} target="_blank" rel="noreferrer">
                                {u.title}
                              </a>
                            ) : (
                              `${u.title}`
                            )}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </JaLayout>
  );
};

export default AboutCert;
